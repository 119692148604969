html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

body {
    padding: 0;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
}

.text-bold {
  font-weight: bold;
}

.text-align-left > * {
  text-align: left !important;
}